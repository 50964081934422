import React from 'react';
import * as S from './default.styles';

const DefaultRender = props => {
  const { children, withItem, handleClick } = props;
  return (
    <>
      {children[0] && !withItem && (
        <S.Button
          onClick={handleClick}
          className={withItem ? `disabled` : ``}
          disabled={withItem}
        >
          {children[0] || `-- no first child --`}
        </S.Button>
      )}

      {withItem && (
        <S.Button
          onClick={handleClick}
          className={!withItem ? `disabled` : ``}
          disabled={!withItem}
        >
          {children[1] || `-- no second child --`}
        </S.Button>
      )}
    </>
  );
};

export default DefaultRender;
