import React, { useState, useEffect, useContext } from 'react';
import { ProductData, BumpOfferData } from '../../utils/productPrice';
import useLocalStorage from '../../hooks/useLocalStorage';
import FunnelContext from '../../context/FunnelContext';
import { Builder } from '@builder.io/react';
import DefaultRender from './render/default';
import PDP2023 from './render/pdp2023';

function AddToLocalCartButton(props) {
  const {
    renderDesign,
    type,
    currentCategory,
    currentTubIndex,
    checkLocalSettings
  } = props;
  const [localCart, setLocalCart] = useLocalStorage('local-cart');
  const [currentCategoryLocal] = useLocalStorage('currentCategory');
  const [withItem, setWithItem] = useState(false);
  const {
    extraObjects,
    setExtraObjects,
    localCartItems,
    setLocalCartItems,
    funnelUpdated,
    setFunnelUpdated
  } = useContext(FunnelContext);
  const _type = type || `product`;
  const _currentCategory =
    (checkLocalSettings ? currentCategoryLocal : currentCategory) ||
    `subscriptions`;
  const _currentTubIndex = currentTubIndex || 0;
  const _currentProduct = `${
    _currentCategory === 'subscriptions' ? 'subscription' : 'onetime'
  }_${_currentTubIndex}`;

  let itemData;
  switch (_type) {
    case 'bump-offer':
      const bumpOfferData = BumpOfferData({
        currentCategory: _currentCategory
      });
      itemData = bumpOfferData[_currentTubIndex];
      break;
    default:
    case 'product':
      const productData = ProductData({
        currentCategory: _currentCategory,
        currentProduct: _currentProduct
      });
      itemData = productData[_currentTubIndex];
      break;
  }

  useEffect(() => {
    localCart?.items.map(i => {
      if (i.variant_id === itemData.checkoutData.variant_id) {
        setWithItem(true);
      }
      return null;
    });
    if (!extraObjects?.localCart) {
      setExtraObjects(
        Object.assign(extraObjects, {
          localCart: localCart
        })
      );
    }
  }, [localCart, itemData, setWithItem, extraObjects, setExtraObjects]);

  const handleClick = () => {
    if (Builder.isEditing) return;

    const { localCart: _localCart } = extraObjects;

    const items = _localCart.items || [];
    const itemsContext = localCartItems || [];
    const finalDiscountCodes = _localCart?.finalDiscountCodes || [];
    let _totalAmount = parseFloat(_localCart?.totalAmount || 0);
    let _totalSavings = parseFloat(_localCart?.totalSavings || 0);

    const newItem = {
      ...itemData.checkoutData,
      bump: true,
      display_title: itemData.display_title
    };

    const discountCode = itemData?.productRef?.product?.discountCode || '';

    if (!withItem) {
      _totalAmount =
        _totalAmount + parseFloat(itemData.checkoutData.discounted_price);
      _totalSavings =
        _totalSavings +
        parseFloat(
          itemData.checkoutData.perceived_rrp -
            itemData.checkoutData.discounted_price
        );
      items.push(newItem);
      itemsContext.push(newItem);
      finalDiscountCodes.push(discountCode);
    } else {
      _totalAmount =
        _totalAmount - parseFloat(itemData.checkoutData.discounted_price);
      _totalSavings =
        _totalSavings -
        parseFloat(
          itemData.checkoutData.perceived_rrp -
            itemData.checkoutData.discounted_price
        );
      items.splice(
        items.findIndex(
          v =>
            v.quantity === newItem.quantity &&
            v.variant_id === newItem.variant_id
        ),
        1
      );
      itemsContext.splice(
        items.findIndex(
          v =>
            v.quantity === newItem.quantity &&
            v.variant_id === newItem.variant_id
        ),
        1
      );
      finalDiscountCodes.splice(
        finalDiscountCodes.findIndex(v => v === discountCode),
        1
      );
    }

    Object.assign(_localCart, {
      items,
      finalDiscountCodes,
      totalAmount: _totalAmount,
      totalSavings: _totalSavings
    });

    setExtraObjects(
      Object.assign(extraObjects, {
        localCart: _localCart
      })
    );
    setLocalCart(
      Object.assign(localCart, {
        ..._localCart
      })
    );
    setLocalCartItems(itemsContext);
    setFunnelUpdated(!funnelUpdated);
    setWithItem(!withItem);
  };

  switch (renderDesign) {
    case 'pdp2023':
      return (
        <PDP2023 withItem={withItem} handleClick={handleClick} {...props} />
      );
    default:
      return (
        <DefaultRender
          withItem={withItem}
          handleClick={handleClick}
          {...props}
        />
      );
  }
}

export default AddToLocalCartButton;
