import React from 'react';
import * as S from './pdp2023.styles';
import { CSSTransition } from 'react-transition-group';
import useButtonEventPressed from '../../../hooks/useButtonEventPressed';

const PDP2023 = ({ withItem, handleClick }) => {
  const duration = 500;

  useButtonEventPressed('add-to-local-cart-press-trigger');

  const width = withItem ? 46 : 110;

  return (
    <S.Container
      onClick={handleClick}
      className="add-to-local-cart-press-trigger"
    >
      <CSSTransition in={withItem} timeout={duration}>
        <>
          <S.RemoveText className={withItem ? `` : `hidden`}>
            Remove
          </S.RemoveText>
          <S.Button
            className={`button ${withItem ? `with-item` : ``}`}
            style={{ width: `${width}px` }}
          >
            <span className={withItem ? `hidden` : ``}>Add</span>
            <span className={`${withItem ? `` : `hidden`} svg`}>
              <svg
                width="24"
                height="18"
                viewBox="0 0 24 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.45744 10.4338L18.3108 0.580485C19.0848 -0.193495 20.3351 -0.193495 21.119 0.580485L22.5082 1.96968C23.2821 2.74366 23.2821 4.00386 22.5082 4.77784L9.85656 17.4195C9.08258 18.1935 7.82238 18.1935 7.0484 17.4195L1.49162 11.8627C0.717638 11.0888 0.717638 9.82856 1.49162 9.05458L2.88081 7.66538C3.65479 6.8914 4.91499 6.8914 5.68897 7.66538L8.45744 10.4338Z"
                  fill="white"
                />
              </svg>
            </span>
          </S.Button>
        </>
      </CSSTransition>
    </S.Container>
  );
};

export default PDP2023;
