import styled from 'styled-components';

export const Button = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  background: #00b67a;
  border-radius: 36px;
  padding: 12px 30px;
  transition: all 400ms ease-in-out;
  position: relative;
  height: 46px;
  min-width: 46px;
  & > svg {
    transition: all 400ms ease-in-out;
  }
  &.with-item {
    padding: 12px 10px 11px;
    & > svg {
      margin-top: 2px;
    }
  }

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    border: 6px solid transparent;
    border-radius: 36px;
    top: -6px;
    left: -6px;
    transition: border 500ms;
  }
  & > span {
    transition: all 400ms ease-in-out;
    opacity: 1;
    &.hidden {
      opacity: 0;
    }
    &.svg {
      display: block;
      margin-top: -22px;
    }
  }
`;

export const RemoveText = styled.span`
  text-decoration: underline;
  margin: auto;
  transition: all 500ms;
  opacity: 1;
  &.hidden {
    opacity: 0;
  }
  &.exit-done {
    opacity: 0;
    display: none;
    width: 0px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  cursor: pointer;

  &.pressed .button:before {
    border: 6px solid rgba(0, 182, 122, 0.2);
  }
`;
